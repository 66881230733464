import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "components/ui/image"
import Clients from "components/banners/clients"
import Layout from "components/layout"
import SEO from "components/seo"
import Products from "components/products/moreProducts"

import "styles/services.scss"

const ServicesPage = () => {
  const images = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "services/banner.png" }) {
        ...imageQuery
      }
      industrial_design: file(
        relativePath: { eq: "services/industrial_design.png" }
      ) {
        ...imageQuery
      }
      manufacturing: file(relativePath: { eq: "services/manufacturing.png" }) {
        ...imageQuery
      }
      mold_design: file(relativePath: { eq: "services/mold_design.png" }) {
        ...imageQuery
      }
    }
  `)
  return (
    <Layout path="/services/">
      <SEO
        title="Services"
        description=""
        keywords={[
          `Rishaba`,
          `Butterfly`,
          `Home Appliances`,
          `Services`,
          `Industrial Design`,
          `Engineering`,
          `Mold Design`,
          `Manufacturing`,
        ]}
        ogUrl="/services"
      />
      <Image image={images.banner} alt="Services Page Banner" />
      <h1 className="center my-50 font-bold">Services</h1>
      <div className="position-relative my-100">
        <h2 className="services-description-left font-size-66 font-bold">INDUSTRIAL DESIGN</h2>
        <Image image={images.industrial_design} alt="Services - Industrial Design" />
      </div>
      <div className="position-relative my-50">
        <h2 className="services-description-right font-size-66 font-bold">
          ENGINEERING & <br /> MOLD DESIGN
        </h2>
        <Image image={images.mold_design} alt="Services - Mold Design" />
      </div>
      <div className="position-relative mt-50">
        <h2 className="services-description-left font-size-66 font-bold">MANUFACTURING</h2>
        <Image image={images.manufacturing} alt="Services - Manufacturing" />
      </div>
      <Products />
      {/* <Clients /> */}
    </Layout>
  )
}

export default ServicesPage
